// import React, { useState } from 'react';
// import { Modal, Button, Form, Dropdown, DropdownButton } from 'react-bootstrap';

// const MyModal = ({ show, handleClose }) => {
//   const [weekdays, setWeekdays] = useState({
//     sunday: false,
//     monday: false,
//     tuesday: false,
//     wednesday: false,
//     thursday: false,
//     friday: false,
//     saturday: false,
//   });
  
//   const [startTime, setStartTime] = useState('');
//   const [endTime, setEndTime] = useState('');
//   const [status, setStatus] = useState('off');

//   // Handle checkbox change for weekdays
//   const handleWeekdayChange = (day) => {
//     setWeekdays(prev => ({ ...prev, [day]: !prev[day] }));
//   };

//   // Handle time change
//   const handleTimeChange = (e, type) => {
//     if (type === 'start') {
//       setStartTime(e.target.value);
//     } else if (type === 'end') {
//       setEndTime(e.target.value);
//     }
//   };

//   // Handle status selection
//   const handleStatusChange = (newStatus) => {
//     setStatus(newStatus);
//   };

//   return (
//     <Modal show={show} onHide={handleClose}>
//       <Modal.Header closeButton>
//         <Modal.Title>Schedule Settings</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         {/* Weekdays selection */}
//         <Form.Group controlId="formWeekdays">
//           <h5>Select Weekdays</h5>
//           {['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].map((day) => (
//             // eslint-disable-next-line react/jsx-key
//             <Button 
//             style={{backgroundColor:weekdays[day]?'green':'red'}}
//             onChange={() => handleWeekdayChange(day)}
//             >{day}</Button>
//             // <Form.Check
//             //   key={day}
//             //   type="checkbox"
//             //   label={day.charAt(0).toUpperCase() + day.slice(1)}
//             //   checked={weekdays[day]}
//             //   onChange={() => handleWeekdayChange(day)}
//             // />
//           ))}
//         </Form.Group>

//         {/* Time range */}
//         <Form.Group controlId="formTime">
//           <Form.Label>Start Time</Form.Label>
//           <Form.Control
//             type="time"
//             value={startTime}
//             onChange={(e) => handleTimeChange(e, 'start')}
//           />
//           <Form.Label>End Time</Form.Label>
//           <Form.Control
//             type="time"
//             value={endTime}
//             onChange={(e) => handleTimeChange(e, 'end')}
//           />
//         </Form.Group>

//         {/* Status dropdown */}
//         <Form.Group controlId="formStatus">
//           <h5>Status</h5>
//           <DropdownButton
//             variant="secondary"
//             id="dropdown-basic-button"
//             title={status.charAt(0).toUpperCase() + status.slice(1)}
//             onSelect={handleStatusChange}
//           >
//             <Dropdown.Item eventKey="off">Off</Dropdown.Item>
//             <Dropdown.Item eventKey="standby">Standby</Dropdown.Item>
//             <Dropdown.Item eventKey="sleep">Sleep</Dropdown.Item>
//           </DropdownButton>
//         </Form.Group>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={handleClose}>
//           Close
//         </Button>
//         <Button variant="primary" onClick={() => { 
//           // Handle form submission logic here
//           handleClose();
//         }}>
//           Save Changes
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default MyModal;

import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Dropdown, DropdownButton, Container, Row } from 'react-bootstrap';
import {  Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import useHttpApiCall from '../../../../services/useHttpApiCall';
const MyModal = ({ show, handleClose ,dataList}) => {
  const [weekdays, setWeekdays] = useState({
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
  });

  // const [startTime, setStartTime] = useState('');
  // const [endTime, setEndTime] = useState('');
  // const [status, setStatus] = useState('off');
        const [selectedItems, setSelectedItems] = useState([]);
        const [schedulePayload, setSchedulePayload] = useState([]);
      
        // const [data] = useHttpApiCall(payloadSlideShows)
        const scheduleCreateResponce = useHttpApiCall(schedulePayload)
  const [scheduleParams,setScheduleParams]=useState(
    {
      name: "Lunch Schedule 1",
      is_active: true,
      machine_status: '',
      machine_status_condition: '',
      tagged_equipment: [
            94
      ],
      schedule_time: [
          {
              schedule_day: 1,
              schedule_from_time: "09:00:00",
              schedule_to_time: "10:00:00"
          }
      ]
    }
    
  ) 
  // Handle button click for weekdays
  const handleWeekdayClick = (day,index) => {
    setWeekdays(prev => ({ ...prev, [day]: !prev[day] }));
console.log('dataList...........',index+1)

  };
  useEffect(()=>{
    console.log('scheduleCreateResponce.............',scheduleCreateResponce)
  },[scheduleCreateResponce])
  const handleSubmit=()=>{
  setSchedulePayload({
        auth: true,
        url: 'v0/shop-floor-view/schedules/',
        method:'POST',
        data:{
          name:scheduleParams?.name,
          is_active:scheduleParams?.is_active,
          machine_status:scheduleParams?.machine_status,
          machine_status_condition:scheduleParams?.machine_status_condition?'SHOULD_BE':'SHOULD_NOT_BE',
          tagged_equipment:scheduleParams?.tagged_equipment,
          schedule_time:scheduleParams?.schedule_time

        }
    
      })
      handleClose()
    }
  // Handle time change
  const handleTimeChange = (e, timeType) => {
    const { value } = e.target;
    setScheduleParams((prevState) => {
      const updatedScheduleTime = [...prevState.schedule_time];
      updatedScheduleTime[0] = {
        ...updatedScheduleTime[0],
        [timeType]: value
      };
      return {
        ...prevState,
        schedule_time: updatedScheduleTime
      };
    });
  };

  // Handle status selection
  // const handleStatusChange = (newStatus) => {
  //   setStatus(newStatus);
  // };
  const handleChange = (e) => {
    const { name,value } = e.target;
    setScheduleParams((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleDropdownChange = (e) => {
    console.log('ffffffff........',e)
    // eslint-disable-next-line no-unsafe-optional-chaining
    // const { name,value } = e?.target;
    setScheduleParams((prevState) => ({
      ...prevState,
      machine_status: e
    }));
  };
  const truncateText = (text, maxLength) => {
    // If the text length exceeds the max length, truncate and add "..."
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };
  const handleItemClick = (e,item) => {

    e.target.checked?setSelectedItems((prev)=>[...prev,item?.id]):setSelectedItems(selectedItems?.filter(prevItem=>prevItem!==item?.id))

  };
  return (
    <Modal className='shopfloor' show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Schedule Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Weekdays selection as buttons */}  <div className='modalLabel mb-2'>
        Name
        </div>
         <input name='name' className='shadow rounded' style={{minHeight:'35px',marginBottom:'10px'}} onChange={handleChange}></input>
        
        <Form.Group controlId="formWeekdays">
          <h5>Select Weekdays</h5>
          <div className="d-flex flex-wrap">
            {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day,index) => (
              <Button
                key={day}
                variant={weekdays[day] ? 'success' : 'secondary'}
                className="m-1"
                onClick={() => handleWeekdayClick(day,index)}
              >
                {day.charAt(0).toUpperCase() + day.slice(1)}
              </Button>
            ))}
          </div>
        </Form.Group>

        {/* Time range */}
        <Form.Group controlId="formTime">
          <Form.Label className='d-block'>Enter Time</Form.Label>
          <Form.Control
            type="time"
            className='mr-5'
            value={scheduleParams?.schedule_time[0].schedule_from_time}
            onChange={(e) => handleTimeChange(e, 'schedule_from_time')}
          />
                  <Form.Control
            type="time"
            value={scheduleParams?.schedule_time[0].schedule_to_time}
            onChange={(e) => handleTimeChange(e, 'schedule_to_time')}
          />
          {/* <Form.Label>End Time</Form.Label>
          <Form.Control
            type="time"
            value={scheduleParams?.schedule_time[0].schedule_to_time}
            onChange={(e) => handleTimeChange(e, 'schedule_to_time')}
          /> */}
        </Form.Group>

        {/* Status dropdown */}
        <Form.Group controlId="formStatus">
          <h5>Status</h5>
          <DropdownButton
            variant="secondary"
            name='machine_status'
            id="dropdown-basic-button"
            title={scheduleParams?.machine_status.charAt(0).toUpperCase() + scheduleParams?.machine_status.slice(1)}
            onSelect={handleDropdownChange}
          >
            <Dropdown.Item eventKey="PRODUCTION">PRODUCTION</Dropdown.Item>
            <Dropdown.Item eventKey="OFF">OFF</Dropdown.Item>
            <Dropdown.Item eventKey="SLEEP">SLEEP</Dropdown.Item>
            <Dropdown.Item eventKey="STANDBY">STANDBY</Dropdown.Item>
          </DropdownButton>
        </Form.Group>
        <Form.Group controlId="formStatus">
          <h5>Should be</h5>
           <div>
                                    {/* <label className="mt-4 mb-2  modal-label">Machine Status condetion</label> */}
                                    <div className="text-left px-3">
                                        <label className="daz-switch mt-1">
                                            <input
                                                type="checkbox"
                                                onChange={() => { handleChange({ target: { name: 'machine_status_condition', value: !scheduleParams.machine_status_condition } }) }}

                                                defaultValue={false}

                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                    </div>

        </Form.Group>
        <h4  className='my-3'>{'Equipment List'}</h4>
        <div className='optionList'>
          <Container>
          {
            dataList?.length>0&&dataList?.map((item)=>(
              <div  key={item?.id} className='py-3 listItem' >
      <Row  className='formListItem my-1'>
      <OverlayTrigger
          placement="bottom"  // Position the tooltip at the bottom of the Col
          overlay={<Tooltip id="tooltip-bottom">{ item?.equipment_name}</Tooltip>}
        >
                      <Col xs={4} title={ item?.equipment_name}> { truncateText( item?.equipment_name,10)}</Col>

        </OverlayTrigger>
                      <Col xs={6}></Col>
                      <Col className="d-flex justify-content-center align-items-center">
                         <input className="form-check-input " type="checkbox"  onChange={(e)=>{handleItemClick(e,item)}}></input>
                      </Col>

                  </Row>
              </div>
            
              
               
            )
          
            )
          }
          </Container>
     

        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={() => { 
          console.log('zzzzzzzzzz..................',scheduleParams,selectedItems)
          // Handle form submission logic here
          handleSubmit();
        }}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MyModal;