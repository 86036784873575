import Table from 'react-bootstrap/Table';
// import {  slideShows } from './data';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import SlideForm from './slideForm';
import useHttpApiCall from '../../../../services/useHttpApiCall';

function ResponsiveExample({isSlides,dataList,tableData}) {
      const [showModal, setShowModal] = useState(false);  
      const [deletePayload, setDeletePayload] = useState(undefined);  
  const [data] = useHttpApiCall(deletePayload)
    
      const navigate = useNavigate();
      const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
    // const handleEdit=()=>{}
      console.log('xxxxxx.........',dataList,tableData)

      useEffect(()=>{
        console.log('xxxxxx.........',data)

      },[data])
    const handleDelete=(item)=>{

      isSlides?setDeletePayload({
        auth: true,
        url: `v0/shop-floor-view/slides/${item?.id}/`,
        method: 'DELETE'
      }):setDeletePayload({
        auth: true,
        url: `v0/shop-floor-view/slide-shows/${item?.id}/`,
        method: 'DELETE'
      })
    }
    const truncateText = (text, maxLength) => {
      // If the text length exceeds the max length, truncate and add "..."
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      }
      return text;
    };
  return (<div>
    <SlideForm show={showModal} dataList={dataList}  handleClose={handleClose} slides={isSlides} heading={isSlides?'Create Slide':'Create slide show'}/>
    <Table responsive striped bordered hover >
      <thead >
        <tr>
      <th>Name</th>
      <th>{!isSlides?'Slides':'Equipments'}</th>
      <th></th>
      {!isSlides&&<th></th>}

        </tr>
      </thead>
      <tbody>
        <tr>

        </tr>
        {
          isSlides?tableData?.map((item)=>
                <tr key={item?.id}>
            <td className='boldNames'>{item?.name}</td>
            <td>
            {
    item?.equipment?.length>0?
    item?.equipment?.map((slide)=><div className='itemSlides' key={slide?.source_mapping}>{truncateText(slide?.equipment_name,10)},{' '}</div>):
    <div className='itemSlides'>No equipments...</div>

}
            </td>
            <td align='center'>
            <i className="medium material-icons notranslate itemIcons" onClick={handleShow}>edit</i>

<i className="medium material-icons notranslate itemIcons delete" onClick={()=>handleDelete(item)}>delete</i>

            </td>
      {!isSlides&& <td><Button className='btn-grad btn btn-primary' onClick={()=>{  navigate('/slideShow')}}>Start</Button></td>}

           

                </tr>
        ):   tableData?.map((item)=>
            <tr key={item?.id}>
        <td className='boldNames'>{item?.name}</td>
        <td>
        {
item?.slides.length>0?
item?.slides?.map((slide)=><div className='itemSlides' key={slide?.slide}>{slide?.slide_name}</div>):
<div className='itemSlides'>No slides...</div>

}
        </td>
        <td align='center'>
        <i className="medium material-icons notranslate itemIcons" onClick={handleShow}>edit</i>

<i className="medium material-icons notranslate itemIcons delete" onClick={()=>handleDelete(item)}>delete</i>

        </td>
  {!isSlides&& <td align='right'  ><Button style={{marginRight:'15px'}}className='btn-grad btn btn-primary' onClick={()=>{  navigate('/slideShow')}}>Start</Button></td>}

       

            </tr>
    )
        }
    
      </tbody>
    </Table>
    <div className='addNewButton'>
   <Button className='btn-grad btn btn-primary ' onClick={handleShow}>Add</Button>

   </div>
    </div>
  );
}

export default ResponsiveExample;