export const slideShows= [
    {
        "id": 1,
        "name": "Power point presentation 1",
        "is_active": true,
        "slides": [
            {
                "slide": 2,
                "slide_name": "Test Slide v2"
            },
            {
                "slide": 3,
                "slide_name": "Demo Slide"
            }
        ]},
        {
            "id": 2,
            "name": "Power point presentation 2",
            "is_active": true,
            "slides": [
                {
                    "slide": 2,
                    "slide_name": "Test Slide v2"
                },
                {
                    "slide": 3,
                    "slide_name": "Demo Slide"
                }
            ]},
            {
                "id": 3,
                "name": "Power point presentation 3",
                "is_active": true,
                "slides": [
                    {
                        "slide": 2,
                        "slide_name": "Test Slide v2"
                    },
                    {
                        "slide": 3,
                        "slide_name": "Demo Slide"
                    }
                ]}
    
    ]
export const SlidesData= [
    	{
        	"name": "Test Slide",
        	"is_active": true,
        	"equipment": []
    	},
    	{
        	"name": "Test Slide 2",
        	"is_active": true,
        	"equipment": [
            	{
                	"source_mapping": 225,
                	"equipment_name": "Floor wash"
            	},
            	{
                	"source_mapping": 226,
                	"equipment_name": "Car wash"
            	}
        	]
    	}
	]
export const dataArray = [
  { heading: "Task 1", value: 50, description: "First task description", timestrap: "2024-12-16T08:55:00" },
  { heading: "Task 2", value: 45, description: "Second task description", timestrap: "2024-12-16T09:00:00" },
  { heading: "Task 3", value: 75, description: "Third task description", timestrap: "2024-12-16T10:00:00Z" },
  { heading: "Task 4", value: 30, description: "Fourth task description", timestrap: "2024-12-16T11:00:00Z" },
  { heading: "Task 5", value: 85, description: "Fifth task description", timestrap: "2024-12-16T12:00:00Z" },
];
export   const sampleData=   [
    {
      id: 94,
      process: 3,
      sub_process_id: 1,
      equipment_id: 1,
      equipment_name: 'Bystronic Laser cutting',
      process_display_name: 'Cutting',
      is_user_preferred: true,
    },
    {
      id: 226,
      process: 5,
      sub_process_id: 4,
      equipment_id: 5,
      equipment_name: 'Car wash',
      process_display_name: 'Washing',
      is_user_preferred: true,
    },
    {
      id: 267,
      process: 7,
      sub_process_id: 1,
      equipment_id: 122,
      equipment_name: 'Chiller 1',
      process_display_name: 'Chillers',
      is_user_preferred: true,
    },
    {
      id: 528,
      process: 9,
      sub_process_id: 3,
      equipment_id: 8,
      equipment_name: 'Milling Machine',
      process_display_name: 'Milling',
      is_user_preferred: false,
    },
    {
      id: 132,
      process: 2,
      sub_process_id: 5,
      equipment_id: 11,
      equipment_name: 'Drilling Equipment',
      process_display_name: 'Drilling',
      is_user_preferred: true,
    },
  ];
