/* eslint-disable no-unused-vars */

import React, { useState } from 'react';
import { Modal, Button} from 'react-bootstrap';
import CreateForm from './createForm';
// import { sampleData } from './data';

const SlideForm = ({ show, handleClose,heading,slides ,dataList}) => {



  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
     <CreateForm itemList={dataList} slides={slides}handleSubmit={(e)=>{console.log('xxxxxxxxxxxx........submit',e)}}/>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={() => { 
          // Handle form submission logic here
          handleClose();
        }}>
          Save Changes
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default SlideForm;