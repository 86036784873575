import React, { useEffect, useState } from 'react';
import {  Alert, Button, Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import useHttpApiCall from '../../../../services/useHttpApiCall';

const CreateForm = ({ itemList,slides }) => {
      const [selectedItems, setSelectedItems] = useState([]);
      const [payload, setPayload] = useState();
      const [payload1, setPayload1] = useState();
      const [data2, loading2, error2] = useHttpApiCall(payload1);
      const [showToast, setShowToast] = useState(false);


      // const [sampleitemList,setSampleitemList]=useState(itemList)
      // console.log('vvvvvv........1',itemList,slides)
    
      // Handle item click
      const handleItemClick = (e,item) => {

        e.target.checked?setSelectedItems((prev)=>[...prev,item?.id]):setSelectedItems(selectedItems?.filter(prevItem=>prevItem!==item?.id))
   
      };
      const handleSubmit=()=>{
      slides?  setPayload1({
          auth: true,
          url: 'v0/shop-floor-view/slides/',
          method:'POST',
          data:{
            name:payload,
            is_active:true,
            tagged_equipment:selectedItems
          }
      
        }):
        setPayload1({
          auth: true,
          url: 'v0/shop-floor-view/slide-shows/',
          method:'POST',
          data:{
            name:payload,
            is_active:true,
            tagged_slides:selectedItems
          }
      
        })

      }
      const handleChange=(e)=>{setPayload(e.target.value)
        
      }
      useEffect(()=>{
        data2?.data?.status==1&& setShowToast(true)
        console.log('vvvvvv1........',data2?.data?.status, loading2, error2)

      },[data2, loading2, error2])
      const truncateText = (text, maxLength) => {
        // If the text length exceeds the max length, truncate and add "..."
        if (text.length > maxLength) {
          return text.slice(0, maxLength) + "...";
        }
        return text;
      };
return(
    <div>
        {/* <div>Create New Slide</div> */}
        <Alert
          className="sidebar-slider_alert"
          show={showToast}
          variant="success"
          onClose={() => setShowToast(false)}>
          <p className="text-center">
            Data created successfully
            {/* {editMode ? 'Alert updated successfully' : 'Alert created successfully'} */}
          </p>
        </Alert>
        <div className='modalLabel mb-2'>
        Name
        </div>
         <input name='name' className='shadow rounded' style={{minHeight:'35px',marginBottom:'10px'}} onChange={handleChange}></input>
        <h4  className='my-3'>{slides?'Equipment List':'Slides'}</h4>
        <div className='optionList'>
          <Container>
          {
            itemList?.length>0&&itemList?.map((item)=>(
              <div  key={item?.id} className='py-3 listItem' >
      <Row  className='formListItem my-1'>
      <OverlayTrigger
          placement="bottom"  // Position the tooltip at the bottom of the Col
          overlay={<Tooltip id="tooltip-bottom">{slides? item?.equipment_name:item?.name}</Tooltip>}
        >
                      <Col xs={4} title={slides? item?.equipment_name:item?.name}> { truncateText(slides? item?.equipment_name:item?.name,10)}</Col>

        </OverlayTrigger>
                      <Col xs={6}></Col>
                      <Col className="d-flex justify-content-center align-items-center">
                         <input className="form-check-input " type="checkbox"  onChange={(e)=>{handleItemClick(e,item)}}></input>
                      </Col>

                  </Row>
              </div>
            
              
               
            )
          
            )
          }
          </Container>
     

        </div>

 
   
     <div>
      
     </div>
     <div className='addNewButton'>
     <Button className='mt-4 btn-grad btn btn-primary' onClick={()=>{handleSubmit()}}>Submit</Button>

   </div>
    
    </div>
)
}
export default CreateForm