import React, { useEffect, useState } from 'react';
import useHttpApiCall from '../../../../services/useHttpApiCall';
// import { Button, Col, Row } from 'react-bootstrap';
const Schemas=()=>{
    // const [schedulePayload, setSchedulePayload] = useState([]);
  const [payloadSlideShows, setPayloadSlideShows] = useState(undefined);

//   const [slideShows, setSlideShows] = useState([]);
//   const [slides, setSlides] = useState([]);

  const [data] = useHttpApiCall(payloadSlideShows)
//   const slideList = useHttpApiCall(payloadSlides)
      
    // const [data] = useHttpApiCall(payloadSlideShows)
    // const scheduleCreateResponce = useHttpApiCall(schedulePayload)
     useEffect(() => {
        setPayloadSlideShows({
          auth: true,
          url: 'v0/shop-floor-view/slide-shows/'
        });

      }, []);
      useEffect(()=>{
        console.log('dataxxxxxxxx...........',data?.data?.data)
      },[data])
return(
<div>
  {/* {
    data?.data?.data?.map((item,index)=> (<div key={index}>
      <Row>
<Col xs={10}>
<div name='name' className='shadow rounded' style={{minHeight:'35px',marginBottom:'10px'}} >{item?.name}</div>
<h5>Select Weekdays</h5>
          <div className="d-flex flex-wrap">
            {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day,index) => (
              <Button
                key={day}
                variant={weekdays[day] ? 'success' : 'secondary'}
                className="m-1"
                // onClick={() => handleWeekdayClick(day,index)}
              >
                {day.charAt(0).toUpperCase() + day.slice(1)}
              </Button>
            ))}
          </div>

</Col>
<Col xs={2}></Col>

      </Row>
      {
        console.log('item............',item)
      }
    </div>))
  } */}

</div>
)
}
export default Schemas