/* eslint-disable no-unused-vars */
// import React from "react";
import React, { useEffect, useState } from 'react';
// import { Card, Row, Col } from 'react-bootstrap';
import useHttpApiCall from '../../../../services/useHttpApiCall';
import './shopfloor.scss'
import MyModal from './scheduleForm';
import CreateForm from './createForm';
import ResponsiveExample from './tableView';
// import {alarm} from './alarm/'
// import { FaEdit, FaTrash } from 'react-icons/fa';  // Font Awesome icons



const ShopFloorViewSlides = () => {

  const [showModal, setShowModal] = useState(false);  
  const [payloadEquipments, setPayloadEquipments] = useState(undefined);
  const [payloadSlides, setPayloadSlides] = useState(undefined);

  const [equipments, setEquipments] = useState([]);
  const [slides, setSlides] = useState([]);

  const [data] = useHttpApiCall(payloadEquipments)
  const [slideList] = useHttpApiCall(payloadSlides)



  useEffect(() => {
    setPayloadEquipments({
      auth: true,
      url: 'v0/equipments/'
    });
    setPayloadSlides({
      auth: true,
      url: 'v0/shop-floor-view/slides/'
    });
  }, []);
  useEffect(()=>{
    if(data && data?.data){
      setEquipments(data?.data?.data)
    }
  },[data])
  useEffect(()=>{
    if(slideList && slideList?.data){
      setSlides(slideList?.data?.data)
    }
  },[slideList])
console.log('time diff........',data?.data?.data)

useEffect(()=>{
  console.log('time diff........',equipments)
},[equipments])
  // handleStart()

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <div className="container mt-4">
      <iframe src="../alarm/emergency-alarm-with-reverb-29431.mp3" allow="autoplay" style={{display:'none'}} id="iframeAudio">
      </iframe> 
      <h4 className="text-center layout-heading">Slides</h4>

      {/* <div>
        <button onClick={handleShow} >schedule setting</button>
      </div> */}
      <MyModal show={showModal}  handleClose={handleClose} dataList={equipments}/>

   {/* <CreateForm itemList={sampleData} handleSubmit={()=>{console.log('xxxxxxxxxxxx........submit')}}/> */}

   <ResponsiveExample dataList={equipments} isSlides={true} tableData={slides}/>
  </div>
 
  );
};

export default ShopFloorViewSlides;
// const ShopFloorView=()=>{
//     return (
//         <div>shop</div>
//     )
// }
// export default ShopFloorView