import React, { useEffect, useState } from 'react';
import { dataArray } from './data';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const SlideShow=()=>{
    
      const [tasks, setTasks] = useState(dataArray);
      const [canPlaySound, setCanPlaySound] = useState(false);
      const [alarmPlaying, setAlarmPlaying] = useState(false); // Track if the alarm is playing
const onlineAudioUrl = "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"; // Replace with your online audio URL

        // Create audio instance for alarm sound
  const alarmSound = new Audio(onlineAudioUrl);

  // Play online audio if conditions are met
  const playAlarm = () => {
    if (!alarmPlaying) {
      alarmSound.play().catch((error) => {
        console.log('Error playing audio:', error);
      });
      setAlarmPlaying(true); // Update state to indicate alarm is playing
    }
  };

  // Stop the alarm sound after 10 seconds
  const stopAlarm = () => {
    setTimeout(() => {
      alarmSound.pause(); // Stop the audio
      alarmSound.currentTime = 0; // Reset to start
      setAlarmPlaying(false); // Update state to indicate alarm is no longer playing
    }, 10000); // Stop after 10 seconds
  };

  // Update card shadows every 3 minutes
  const updateCardShadows = () => {
    const updatedTasks = tasks.map((task) => {
      const isLessThanOneMinute = calculateTimeDifference(task.timestrap);
      return {
        ...task,
        boxShadowColor: isLessThanOneMinute ? 'red' : '', // Red shadow if time difference is less than 1 minute
        blinkEffect: isLessThanOneMinute,
      };
    });
    setTasks(updatedTasks);

    // Start alarm if time difference is less than 1 minute and stop after 10 seconds
    if (canPlaySound) {
      tasks.forEach((task) => {
        if (calculateTimeDifference(task.timestrap)) {
          playAlarm(); // Play alarm sound
          stopAlarm(); // Stop the alarm after 10 seconds
        }
      });
    }
  };
useEffect(()=>{
  setCanPlaySound(true)
},[])
  // Trigger checking every 3 minutes
  useEffect(() => {
    const intervalId = setInterval(() => {
      updateCardShadows(); // Update shadows every 3 minutes
    }, 60 * 1000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [tasks, canPlaySound]);

  // const handleStart = () => {
  //   setCanPlaySound(true); // Allow sound to play after user interaction
  //   alert("Started monitoring tasks! The alarm will sound if any task's time is within 1 minute.");
  // };

  const handleEdit = (index) => {
    alert(`Edit Task ${index + 1}`);
  };

  const handleDelete = (index) => {
    const updatedTasks = tasks.filter((_, i) => i !== index);
    setTasks(updatedTasks);
  };
    const calculateTimeDifference = (timestrap) => {
      const taskTime = new Date(timestrap).getTime();
      const currentTime = new Date().getTime();
      const timeDiff = currentTime - taskTime;
      const oneMinute = 20*60 * 60 * 1000; // 1 minute in milliseconds
      return timeDiff <= oneMinute; // Returns true if time difference is less than 1 minute
    };
    function formatTime(timestamp) {
        const date = new Date(timestamp);
        const options = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
        return date.toLocaleTimeString([], options); // Format to only show time
      }
    return(
<div>
<h6 className="inner-breadcrumb">
        <Link to={'/shop_floor'}>
          {' '}
          <i className=" material-icons notranslate graphoptionicons flip-icon">forward</i>Back
        </Link>
      </h6>
      <div className='row'>
      <div className='col-2'></div>
        <div className='col-8'>
        <h4 className="text-center layout-heading">Power point presentation 1</h4>

        </div>
        <div className='col-2'>
        <div className="me-3">
      
          </div>
        </div>
      </div>
   
      {/* =========================card list======================== */}
      <Row>
      {tasks.map((task, index) => {
         const boxShadowColor = calculateTimeDifference(task.timestrap);
        return(
        <Col sm={12} md={6} lg={4} key={index} className="mb-4">
          <Card style={{ boxShadow: `0px 4px 8px 0px ${boxShadowColor}`, transition: 'box-shadow 0.3s ease' }} className='card'>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <Card.Title>
                  <div>
                  {task.heading}
                  </div>
                </Card.Title>
                <div>
                <i className="medium material-icons notranslate icons" onClick={handleEdit}>edit</i>

                <i className="medium material-icons notranslate icons delete" onClick={handleDelete}>delete</i>

                </div>
              </div>
              <Card.Text className='shop-floor-value'>
                {task.value}Vw
              </Card.Text>
              <Card.Text className='shop-floor-description'>
               {task.description}
              </Card.Text>
              <Card.Text>
                <div className='text-end'>
                <small style={{ color: `0px 4px 8px 0px ${boxShadowColor}`, transition: 'box-shadow 0.3s ease' }} className="text-muted">{formatTime(task.timestrap)}</small>

                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      )})}
    </Row> 
    {/* ------------------------------------------------------------- */}
</div>

    )
}